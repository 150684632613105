import React, { useState } from "react";

function AddUser() {
    const [modal, setModal] = useState(false);

    const toggleModalAddUser = () => {
        setModal(!modal);
    };

    if(modal) {
        document.body.classList.add('active-modal');
        
    } else {
        document.body.classList.remove('active-modal')
    }

    return(
        <div>
            <button onClick={toggleModalAddUser} className="button_add_do">
                Ajouter un donneur d'ordre suppléant
            </button>


            {modal && (
                <div>
                    <div className="name_newpt">DONNEUR D'ORDRE SUPPLÉANT N°2</div>
                    <div className="content_descnewpt_input">
                        <input type="text" placeholder="Prénom" />
                        <input type="text" placeholder="Nom" />
                        <input type="text" placeholder="Téléphone" />
                        <input type="text" placeholder="Email" />
                    </div>
                </div>
            )}
        </div>
    )
}

export default AddUser;