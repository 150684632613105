import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from "@fortawesome/free-solid-svg-icons";      
                
function CardVSPT(props) {

    return(
        <div className="box_vspt">
            <div className="box_vnspt_header">
                {props.prenom} {props.nom}
            </div>
            <div className="box_vspt_content">
                {props.date} <br />
                {props.nameEE}
            </div>
            <div className="box_vspt_button">
                <FontAwesomeIcon className="check" icon={ faCheck } />
                Signé N
            </div>
        </div>
    );
}
export default CardVSPT;