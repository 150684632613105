import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from "@fortawesome/free-solid-svg-icons";

function AffichePT(props) {
    
    const fav = props.favoris_PT;
    if (fav === 1){
        <FontAwesomeIcon icon={ faStar } />
    } else {
        <FontAwesomeIcon icon={ faStar } style={{color: "white"}}/>
    }
    return(
    <>
        {fav}
        <div className="numeroPT">{props.numero_PT}</div>
        <div className="infosPT">
            <div>{props.titre_pt}</div>
            <div></div>
        </div>
        <div className="datesPT">
            <div>{props.entreprise_exterieure_PT.date_debut_operations}</div>
            <div>01/04/22</div>
        </div>
        <div className="rdpPT">{props.entreprise_utilisatrice_PT.contact_RdP}azezaee</div>
    </>
    )
};

export default AffichePT;