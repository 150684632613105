import React, { Component } from "react";
import "./KPI.css";

class KPI extends Component {
    render() {
        return(
            <div className="KPI">
                <div className="score te">
                    <div className="cadre_graph">
                        <div className="graph">
                            dskuhfg
                        </div>
                        <h5>83%</h5>
                    </div>
                    <p>Score de tournée</p>
                </div>
                <div className="actions te">
                    <div className="cadre">
                        <div className="nombre">
                            7
                        </div>
                    </div>
                    <p>Actions à réaliser</p>
                </div>
                <div className="coactivite te">
                    <div className="cadre">
                        <div className="nombre">
                            18
                        </div>
                    </div>
                    <p>Coactivité</p>
                </div>
                <div className="deviance te">
                    <div className="cadre">
                        <div className="nombre">
                            32
                        </div>
                    </div>
                    <p>Déviance</p>
                </div>
            </div>
        );
    }
}
export default KPI;