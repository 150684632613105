import React, { Component } from "react";
import "./Bandeau.css";
import avatar_pt from "../../../assets/images/Accueil/avatar_pt.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from "@fortawesome/free-solid-svg-icons";

class Accueil extends Component {
    render() {
        return(
            <div className="bandeau">

                <div className="top">
                    <div className="logo">
                        logo_usine
                    </div>
                
                    <div className="avatar">
                        <img src={avatar_pt} />
                        <h3>Bonjour user_name</h3>
                    </div>

                    <div className="deconnexion">
                        <FontAwesomeIcon icon={ faLock } />
                    </div>
                    
                </div>
                
            </div>
        );
    }
}

export default Accueil;