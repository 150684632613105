import React, { Component } from "react";
import Accueil from "../../components/Accueil/Accueil";

class Site extends Component {
    render() {
        return(
            <>
                <Accueil />
            </>
        );
    }
}

export default Site;