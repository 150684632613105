import React, { Component } from "react";
import "./Description.css";
import Navbar from "../../NavBar/Navbar";
import NavbarNewPT from "../NavbarNewPT/NavbarNewPT"
import HeaderNewPT from "../HeaderNewPT/HeaderNewPT";
import AddDO from "./Boutons/AddDO/AddDO";
import AddExploitant from "./Boutons/AddExploitant/AddExploitant";
import AddEST from "./Boutons/AddEST/AddEST";

class DescriptionNewPT extends Component {
    render() {
        return(
            <div>
                <Navbar />
                <NavbarNewPT />
                <HeaderNewPT titre='Description du travail' />
                <div className="outerbox_newpt">
                    <div className="content_descnewpt">
                        <div>
                            <div className="name_newpt">NOM DU PERMIS</div>
                            <input type="text" placeholder="Exemple: Lorem ipsum dolore" />
                        </div>
                        <div>
                            <div className="name_newpt">RÉDACTEUR DU PERMIS</div>
                            <div className="info_rdp_newpt">
                                <p>Prénom</p>
                                <p>Nom</p>
                                <p>Téléphone</p>
                                <p>Email</p>
                            </div>
                        </div>
                        <div>
                            <div className="name_newpt">DONNEUR D'ORDRE SUPPLÉANT N°1</div>
                            <div className="content_descnewpt_input">
                                <input type="text" placeholder="Prénom" />
                                <input type="text" placeholder="Nom" />
                                <input type="text" placeholder="Téléphone" />
                                <input type="text" placeholder="Email" />
                            </div>
                            
                            <div className="button_add_do">
                                <AddDO />
                            </div>
                        </div>
                        <div className="border_grey_newpt">
                            <div className="name_newpt">EXPLOITANT N°1</div>
                            <div className="content_descnewpt_input">
                                <input type="text" placeholder="Prénom" />
                                <input type="text" placeholder="Nom" />
                                <input type="text" placeholder="Téléphone" />
                                <input type="text" placeholder="Email" />
                            </div>
                            <div className="button_add_do">
                                <AddExploitant />
                            </div>
                        </div>
                        <div>
                            <div className="name_newpt">ENTREPRISE EXTERIEURE</div>
                            <div className="content_descnewpt_input">
                                <input type="text" placeholder="Nom de l'entreprise" />
                                <input type="text" placeholder="Site" />
                                <input type="text" placeholder="Service / Atelier" />
                                <input type="text" placeholder="Ligne / Étage" />
                                <input type="text" placeholder="Date de la PV/PDT" />
                                <input type="text" placeholder="Date de début des opérations" />
                                <input type="text" placeholder="Date de fin des opérations" />
                            </div>
                        </div>
                        <div className="border_grey_newpt">
                            <div className="name_newpt">EXÉCUTANT CHARGÉ DES TRAVAUX</div>
                            <div className="content_descnewpt_input">
                                <input type="text" placeholder="Prénom" />
                                <input type="text" placeholder="Nom" />
                                <input type="text" placeholder="Téléphone" />
                                <input type="text" placeholder="Email" />
                            </div>
                            <div className="button_add_do">
                                <AddEST />
                            </div>
                        </div>
                        <div>
                            <div className="name_newpt">DESCRIPTION DES DIFFÉRENTES PHASES DE L'OPÉRATION A RÉALISER</div>
                            <div className="content_descnewpt_input">
                                <textarea type="text" placeholder="" />
                            </div>
                        </div>
                    </div> 
                </div>
                
            </div>
        );
    }
}

export default DescriptionNewPT;