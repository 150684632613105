import React, { Component } from "react";
import "./Intervenants.css";
import Navbar from "../../NavBar/Navbar";
import NavbarNewPT from "../NavbarNewPT/NavbarNewPT"
import HeaderNewPT from "../HeaderNewPT/HeaderNewPT";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";

class IntervenantsPT extends Component {
    
    render() {
        return(
            <div>
                <Navbar />
                <NavbarNewPT />
                <HeaderNewPT titre='Intervenants' />
                
                <div className="contentIntervenantNewPT">
                    <div className="bandeauDateI">
                        <div className="dateJourI">Date du jour</div>
                        <div className="searchI">
                            <button><FontAwesomeIcon icon={ faMagnifyingGlass } /><span>Rechercher</span></button>
                            <button><FontAwesomeIcon icon={ faMagnifyingGlass } />Modifier la date</button>
                        </div>
                    </div>
                </div> 
                
                
            </div>
        );
    }
}

export default IntervenantsPT;