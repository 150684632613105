import React, { useRef } from 'react';
import "./PDFValidateurs.css";
import { useReactToPrint } from 'react-to-print';
import { ComponentToPrint } from './ComponentToPrint';

const Example = () => {
    // partie où l'on récupère les données à imprimer
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    // partie checkbox pour chaque validateur
    const [checkedRdP, setCheckedRdP] = React.useState(false);
    const handleChangeRdP = () => {
        setCheckedRdP(!checkedRdP);
    };

    const [checkedDO, setCheckedDO] = React.useState(false);
    const handleChangeDO = () => {
        setCheckedDO(!checkedDO);
    };
    const [checkedDO2, setCheckedDO2] = React.useState(false);
    const handleChangeDO2 = () => {
        setCheckedDO2(!checkedDO2);
    };
    const [checkedExploitant, setCheckedExploitant] = React.useState(false);
    const handleChangeExploitant = () => {
        setCheckedExploitant(!checkedExploitant);
    };
    const [checkedExploitant2, setCheckedExploitant2] = React.useState(false);
    const handleChangeExploitant2 = () => {
        setCheckedExploitant2(!checkedExploitant2);
    };
    const [checkedCT, setCheckedCT] = React.useState(false);
    const handleChangeCT = () => {
        setCheckedCT(!checkedCT);
    };
    const [checkedCT2, setCheckedCT2] = React.useState(false);
    const handleChangeCT2 = () => {
        setCheckedCT2(!checkedCT2);
    };

    return (
        <div>
            <div className='CheckboxValidateur'>
                <Checkbox 
                    label="RdP"
                    value={checkedRdP}
                    onChange={handleChangeRdP}
                />

                <Checkbox 
                    label="DO"
                    value={checkedDO}
                    onChange={handleChangeDO}
                />
                <Checkbox 
                    label="DO2"
                    value={checkedDO2}
                    onChange={handleChangeDO2}
                />
                <Checkbox 
                    label="Exploitant"
                    value={checkedExploitant}
                    onChange={handleChangeExploitant}
                />
                <Checkbox 
                    label="Exploitant2"
                    value={checkedExploitant2}
                    onChange={handleChangeExploitant2}
                />
                <Checkbox 
                    label="CT"
                    value={checkedCT}
                    onChange={handleChangeCT}
                />
                <Checkbox 
                    label="CT2"
                    value={checkedCT2}
                    onChange={handleChangeCT2}
                />
            </div>
            
            

            <ComponentToPrint ref={componentRef} />
            <button 
                onClick={handlePrint} 
                className="modal_button_imprimer_validateur"
            >
                Imprimer la sélection 
            </button>
        </div>
    );
};

const Checkbox = ({ label, value, onChange }) => {
    return (
        <label style={{marginBottom:'30px', marginLeft:'20px',display:'flex', alignItems:'center'}}>
            <input 
                type="checkbox"
                checked={value}
                onChange={onChange}
                style={{marginRight:'15px', width:'20px', height:'20px'}}
                />
            {label}
        </label>
    );
};

export default Example;