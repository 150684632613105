import React, { useState } from "react";
import "./MenuContextuel.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from "@fortawesome/free-solid-svg-icons";

function Filtre(props) {
    const [modal, setModal] = useState(false);

    const toggleModalMC = () => {
        setModal(!modal);
    };

    if(modal) {
        document.body.classList.add('active-modal')
    } else {
        document.body.classList.remove('active-modal')
    }

    return(
        <div className="menu_contextuel">
            <button onClick={toggleModalMC} className="bouton_mc">
                <FontAwesomeIcon icon={ faBars } />
            </button>


            {modal && (
                <div className="pos_modal">
                    <div className="modal_mc">
                        <div>
                            Visualiser
                        </div>
                        <div>
                            Télécharger
                        </div>
                        <div>
                            Imprimer
                        </div>
                        <div>
                            Clôturer
                        </div>
                        <div>
                            Envoyer par mail
                        </div>
                        <div>
                            Créer une TC associée
                        </div>
                        <div>
                            Créer une copie
                        </div>
                    </div>
                </div> 
            )}
        </div>
    )
}

export default Filtre;