import React, { useState, useEffect, useRef } from "react";
import "./AddValidateur.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus } from "@fortawesome/free-solid-svg-icons";

function AddValidateur() {
    const [modal, setModal] = useState(false);

    const toggleModalAddValidateur = () => {
        setModal(!modal);
    };

    if(modal) {
        document.body.classList.add('active-modal')
        
    } else {
        document.body.classList.remove('active-modal')
    }

    const [prenom, setPrenom] = useState('');
    const [nom, setNom] = useState('');
    const [tel, setTel] = useState('');
    const [mail, setMail] = useState('');
    const [nomEE, setNomEE] = useState('');


    return(
        <div>
            <button onClick={toggleModalAddValidateur} className="button_add_validateur">
                <FontAwesomeIcon icon={ faCirclePlus } />
                Ajouter un validateur
            </button>


            {modal && (
                <div className="AddValidateur_overlay">
                    <div className="AddValidateur_wrapper">
                        <div className="AddValidateur_modal">
                            <div className="AddValidateur_header">
                                Nouveau Validateur
                                <button
                                    type="button"
                                    className="modal-close-button"
                                
                                >
                                    X
                                </button>
                            </div>
                            <div className="AddValidateur_content">
                                <input type="text" value={prenom} placeholder="Prénom" />
                                <input type="text" value={nom} placeholder="Nom" />
                                <input type="text" value={tel} placeholder="Téléphone" />
                                <input type="text" value={mail} placeholder="Email" />
                                <input type="text" value={nomEE} placeholder="Nom de l'Entreprise" />
                            </div>
                            <button
                                type="button"
                                className="modal_button_imprimer_validateur"
                            >Ajouter le nouvel utilisateur</button>
                        </div>
                    </div>
                    
                    
                </div>
            )}
        </div>
    )
}

export default AddValidateur;