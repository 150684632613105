import React, { useState } from "react";

function BoutonAR() {
    const [modal, setModal] = useState(false);

    const toggleModalBoutonAR = () => {
        setModal(!modal);
    };

    if(modal) {
        document.body.classList.add('active-modal');
        
    } else {
        document.body.classList.remove('active-modal')
    }

    return(
        <div>
            
            <button onClick={toggleModalBoutonAR} className="">
                COVID-19
            </button>


            {modal && (
                <div>
                    <div className="name_newpt">EU</div>
                    <div className="name_newpt">EE/ES</div>
                    <div className="checkbox_mode_operatoire">
                            {/* <input
                                className="checkbox"
                                id="checkbox"
                                name="modeOperatoireEE"
                                type="checkbox"
                                checked={this.state.modeOperatoireEE}
                                onChange={this.handleInputChange} 
                            />
                            <label for="checkbox">Avant le démarrage des travaux, le permis de travail sera signé par tous les acteurs du permis de travail, à l’identique à la fin du poste de travail.</label> */}
                    </div>
                </div>
            )}
        </div>
    )
}

export default BoutonAR;