import React from "react";
import "./Header.css";
import Filtre from "../../Filtre/Filtre";

function Header(props) {
    

        return(
            <div>
                <div className="header_pt">
                    <div className="titre_page">
                        <h1>{props.titre}</h1>
                    </div>

                    <Filtre/>
                </div>
                
            </div>
        
        );
};

export default Header;