import React from 'react';
import './App.css';
import Site from "./containers/Site/Site";
import PT from "./components/PT/PT";
import Clotures from "./components/PT/Clotures/Clotures";
import Favoris from "./components/PT/Favoris/Favoris";
import Description from "./components/PT/NewPT/Description/Description";
import Recommandations from './components/PT/NewPT/RecommandationsEE/Recommandations';
import ValidationPT from './components/PT/NewPT/Validation/Validation';
import AnalyseRisquePT from './components/PT/NewPT/AnalyseRisque/AnalyseRisque';
import IntervenantsPT from './components/PT/NewPT/Intervenants/Intervenants';
import FinChantierPT from './components/PT/NewPT/FinChantier/FinChantier';
import DocumentsPT from './components/PT/NewPT/Documents/Documents';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

// const Site = require("./containers/Site/Site");
// const PT = require("./components/PT/PT");
// const Clotures = require("./components/PT/Clotures/Clotures");
// const Favoris = require("./components/PT/Favoris/Favoris");
// const Description = require("./components/PT/NewPT/Description/Description");
// const Recommandations = require('./components/PT/NewPT/RecommandationsEE/Recommandations');
// const ValidationPT = require('./components/PT/NewPT/Validation/Validation');
// const AnalyseRisquePT = require('./components/PT/NewPT/AnalyseRisque/AnalyseRisque');
// const IntervenantsPT = require('./components/PT/NewPT/Intervenants/Intervenants');
// const FinChantierPT = require('./components/PT/NewPT/FinChantier/FinChantier');
// const DocumentsPT = require('./components/PT/NewPT/Documents/Documents');
// const { BrowserRouter, Router, Routes, Route } = require("react-router-dom");

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/pt/documents" element={ <DocumentsPT /> } / >
        <Route path="/pt/fin-chantier" element={ <FinChantierPT /> } / >
        <Route path="/pt/tournee-chantier" element={ <Recommandations /> } / >
        <Route path="/pt/intervenants" element={ <IntervenantsPT /> } / >
        <Route path="/pt/validation" element={ <ValidationPT /> } / >
        <Route path="/pt/analyse-risque" element={ <AnalyseRisquePT /> } / >
        <Route path="/pt/recommandations" element={ <Recommandations /> } / >
        <Route path="/pt/description" element={ <Description /> } / >
        <Route path="/clotures" element={ <Clotures /> } / >
        <Route path="/favoris" element={ <Favoris /> } / >
        <Route path="/pt" element={ <PT /> } / >
        <Route path="/" element={ <Site /> } / >
        
      </Routes>
    </Router>
      
    
  );
}

export default App;
