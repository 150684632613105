import React, { useState } from "react";
import "./AddExploitant.css";

function AddExploitant() {
    const [modal, setModal] = useState(false);

    const toggleModalAddExploitant = () => {
        setModal(!modal);
    };

    if(modal) {
        document.body.classList.add('active-modal');
        
    } else {
        document.body.classList.remove('active-modal')
    }

    return(
        <div>
            <button onClick={toggleModalAddExploitant} className="button_add_do">
                Ajouter un exploitant
            </button>


            {modal && (
                <div className="new_exploitant">
                    <div className="name_newpt">EXPLOITANT N°2</div>
                    <div className="content_descnewpt_input">
                        <input type="text" placeholder="Prénom" />
                        <input type="text" placeholder="Nom" />
                        <input type="text" placeholder="Téléphone" />
                        <input type="text" placeholder="Email" />
                    </div>
                </div>
            )}
        </div>
    )
}

export default AddExploitant;