import React from "react";
import "./VisaNonSigne.css";
import CardVSNPT from "./CardVSN/CardVSN";

function VisaNonSignePT() {

    return(
        <div className="content_vnspt">
            <div className="titre_vnspt">
                VISA À SIGNER
            </div>
            <div className="display_vnspt">
                <CardVSNPT 
                    prenom="Rdp"
                    nom=""
                    date="02/03/2022"
                    nameEE="HyperFluid"
                />      
                <CardVSNPT 
                    prenom="DO1"
                    nom=""
                    date="02/03/2022"
                    nameEE="HyperFluid"
                />      
                <CardVSNPT 
                    prenom="DO2"
                    nom=""
                    date="02/03/2022"
                    nameEE="HyperFluid"
                />      
                <CardVSNPT 
                    prenom="Exploitant1"
                    nom=""
                    date="02/03/2022"
                    nameEE="HyperFluid"
                />   
            </div>
            
        </div>
    )
}

export default VisaNonSignePT;