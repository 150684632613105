import React from "react";
import "./Navbar.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from "@fortawesome/free-solid-svg-icons";
import {NavLink} from "react-router-dom";

const Navbar = (props) => (
        <>
            <div className="navbar_pt">
                <div className="top_navbar">
                    <NavLink end to="/" className="nav-link">Accueil<FontAwesomeIcon icon={ faHome } /></NavLink>
                    <NavLink end to="/pt/description" className="nav-link">Nouveau PT</NavLink>
                </div>
                <div className="pt_navbar">
                    <div className="text_navbar">
                        Permis de travaux
                    </div>
                    <NavLink end to="/pt" className="nav-link">Ouverts</NavLink>
                    <NavLink end to="/clotures" className="nav-link">Cloturés</NavLink>
                    <NavLink end to="/favoris" className="nav-link">Favoris</NavLink>
                </div>
            </div>
        </>
);

export default Navbar;