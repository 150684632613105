import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignature } from "@fortawesome/free-solid-svg-icons";      
                
function CardVSNPT(props) {

    return(
        <div className="box_vnspt">
            <div className="box_vnspt_header">
                {props.prenom} {props.nom}
            </div>
            <div className="box_vnspt_content">
                {props.date} <br />
                {props.nameEE}
            </div>
            <div className="box_vnspt_button">
                <button
                    type="button"
                >
                    <FontAwesomeIcon icon={ faSignature } />Signer le VISA
                </button>
            </div>
        </div>
    );
}
export default CardVSNPT;