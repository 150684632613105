import React from "react";
import "./NavbarNewPT.css";
import {NavLink} from "react-router-dom";

const NavbarNewPT = (props) => (
        <>
            <div className="navbar_newpt">
                <div className="numero_newpt">
                        <p>NUMÉRO DE PERMIS</p>
                        <h5>XXXX XXX</h5>
                </div>
                <div className="content_navbar_newpt">
                    <NavLink end to="/pt/description" exact activeclassname="isActive" className="navlink_newpt">Description</NavLink>
                    <NavLink end to="/pt/recommandations" exact className="navlink_newpt">Recommandations</NavLink>
                    <NavLink end to="/pt/analyse-risque" exact className="navlink_newpt">Analyse des risques</NavLink>
                    <NavLink end to="/pt/validation" exact className="navlink_newpt">Validation</NavLink>
                    <NavLink end to="/pt/intervenants" exact className="navlink_newpt">Intervenants</NavLink>
                    <NavLink end to="/pt/tournee-chantier" exact className="navlink_newpt">Tournée de chantier</NavLink>
                    <NavLink end to="/pt/fin-chantier" exact className="navlink_newpt">Fin de chantier</NavLink>
                    <NavLink end to="/pt/documents" exact className="navlink_newpt">Documents</NavLink>
                    
                </div>
            </div>
        </>
);

export default NavbarNewPT;