import React from "react";
import "./ComponentToPrint.css";
import PDFHeader from "../PDFHeader/PDFHeader";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignature } from "@fortawesome/free-solid-svg-icons";
import { faFileSignature } from "@fortawesome/free-solid-svg-icons";

// Using a class component, everything works without issue
export class ComponentToPrint extends React.PureComponent {
    render() {
        return (
            <div className="PDFValidateurContent">
                <PDFHeader 
                    numPermis="PT-654-654"
                    titrePDF="Liste des validateurs"
                    prenom="John"
                    nom="Doe"
                />
                
                
                <div className="ArrayValidateur">
                    <table>
                        <thead>
                            <tr>
                                <th colspan="1">Validateur</th>
                                <th colspan="1">Date</th>
                                <th colspan="1">Signature*</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th scope="row">Rédacteur du PT</th>
                                <td>02/03/2022</td>
                                <td><FontAwesomeIcon icon={ faSignature } style={{fontSize:'2em'}}/></td>
                            </tr>
                            <tr>
                                <th scope="row">Donneur d'ordre</th>
                                <td>02/03/2022</td>
                                <td><FontAwesomeIcon icon={ faFileSignature } style={{fontSize:'2em'}}/></td>
                            </tr>
                            <tr>
                                <th scope="row">Donneur d'ordre 2</th>
                                <td>02/03/2022</td>
                                <td><FontAwesomeIcon icon={ faSignature } style={{fontSize:'2em'}}/></td>
                            </tr>
                            <tr>
                                <th scope="row">Exploitant 1</th>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <th scope="row">Exploitant 2</th>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <th scope="row">Chargé de Travaux</th>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <th scope="row">Chargé de Travaux ST</th>
                                <td></td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <p style={{textAlign:'center', fontSize:'12px'}}>*Seules les lignes n'ayant pas de signatures doivent êtres signées et datées.<br/><FontAwesomeIcon icon={ faSignature }/> : déjà signé numériquement.<br /><FontAwesomeIcon icon={ faFileSignature }/> : déjà signé manuellement</p>
            </div>
        );
    }
}
