import React, { Component } from "react";
import Navbar from "../NavBar/Navbar";
import Header from "../Header/Header";
import "../TablePT.css";
import MenuContextuel from "../MenuContextuel/MenuContextuel";
import axios from "axios";
import AffichePT from "../AffichePT/AffichePT";

class Clotures extends Component {

    state = {
        pts : null
    }

    componentDidMount = () => {
        axios.get(`http://localhost/API-PT/front/clotures`)
            .then(reponse => {
                this.setState({pts:Object.values(reponse.data)});
                // console.log(reponse);
            })
    }
    render() {
        return(
            <div>
                <Header titre='Cloturés' />
                <Navbar />
                <div className="tableau_pt">
                    <div className="tableau_header">
                        <div>
                            Favoris
                        </div>
                        <div>
                            Numéro
                        </div>
                        <div>
                            <div>
                                Titre du PT
                            </div>
                            <div>
                                Nom de l'EE
                            </div>
                        </div>
                        <div>
                            <div>
                                Date de début
                            </div>
                            <div>
                                Date de fin
                            </div>
                        </div>
                        <div>
                            RdP
                        </div>
                        <div>
                            Options
                        </div>
                    </div>
                    
                    {
                        this.state.pts &&
                        this.state.pts.map(pt => {
                            return (
                                <div className="tableau_content">
                                    <AffichePT {...pt} key={pt.id}/>
                                    <MenuContextuel />
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        );
    }
}

export default Clotures;