import React, { Component } from "react";
import "./AnalyseRisque.css";
import Navbar from "../../NavBar/Navbar";
import NavbarNewPT from "../NavbarNewPT/NavbarNewPT"
import HeaderNewPT from "../HeaderNewPT/HeaderNewPT";
import BoutonAR from "./BoutonAR/BoutonAR";

class AnalyseRisquePT extends Component {
    constructor(props) {
        super(props);
        this.state = {
          modeOperatoireEE: true,
        };
    
        this.handleInputChange = this.handleInputChange.bind(this);
    }
    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
    
        this.setState({
          [name]: value
        });
    }
    render() {
        return(
            <div>
                <Navbar />
                <NavbarNewPT />
                <HeaderNewPT titre='Analyse de risque' />
                <div className="outerbox_newpt">
                    <div className="content_ARnewpt">
                        <div className="name_newpt">CHECKLIST</div>
                        <div className="checkbox_mode_operatoire">
                            <form>
                                <input
                                    className="checkbox"
                                    id="checkbox"
                                    name="modeOperatoireEE"
                                    type="checkbox"
                                    checked={this.state.modeOperatoireEE}
                                    onChange={this.handleInputChange} 
                                />
                                <label for="checkbox">Mode opératoire fourni par l'EE</label>
                                
                            </form>
                        </div>
                        <BoutonAR />
                    </div> 
                </div>
                
            </div>
        );
    }
}

export default AnalyseRisquePT;