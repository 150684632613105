import React from "react";
import "./PDFHeader.css";  
                
function PDFHeader(props) {

    return(
        <div className="PDFHeader">
            <div className="LeftHeader">
                NUMÉRO DE PT <br />
                {props.numPermis} <br/><br/>
                Rédacteur du PT : <br/>{props.prenom} {props.nom}
            </div>
            <div className="RightHeader">
                <h1>{props.titrePDF}</h1>
            </div>
        </div>
    );
}
export default PDFHeader;