import React, { Component } from "react";
import "./Menu.css";
import pt_menu from "../../../assets/images/Accueil/pt/Union.png";
import tc_menu from "../../../assets/images/Accueil/tc/Group21.png";
import pp_menu from "../../../assets/images/Accueil/pp/Group.png";
import vigilance from "../../../assets/images/Accueil/vigilance/Group12.png";
import reglage from "../../../assets/images/Accueil/reglage/roue.png";
import loto from "../../../assets/images/Accueil/loto/loto.png";


class Menu extends Component {
    render() {
        return(
            <div className="outer-div">
                <div className="inner-div">

                    <div className="menu_top">

                        <div className="pt">
                            <a href="/pt">
                                <div className="pt_menu">
                                    <img src={pt_menu}  />
                                </div>
                                <h2>Permis de travaux</h2>
                            </a>
                        </div>

                        <div className="tc">
                            <a href="#">
                                <div className="tc_menu">
                                    <img src={tc_menu}  />
                                </div>
                                <h2>Tournée de chantier</h2>
                            </a>
                        </div>

                        <div className="pp">
                            <a href="../../../../assets/routes/accueil.php">
                                <div className="pp_menu">
                                    <img src={pp_menu} />
                                </div>
                                <h2>Plan de prévention</h2>
                            </a>
                        </div>

                        <div className="vigilance">
                            <a href="#">
                                <div className="vigilance_menu">
                                    <img src={vigilance} />
                                </div>
                                <h2>Obligation de vigilance</h2>
                            </a>
                        </div>
                    </div>
                    <div className="menu_bot">

                        <div className="loto">
                            <a href="#">
                                <div className="loto_menu">
                                    <img src={loto}  />
                                </div>
                                <h2>LOTO</h2>
                            </a>
                        </div>

                        <div className="reglageUser">
                            <a href="#">
                                <div className="reglage_user">
                                    <img src={reglage}  />
                                </div>
                                <h2>Réglages du compte</h2>
                            </a>
                        </div>

                        <div className="reglageAdmin">
                            <a href="#">
                                <div className="reglage_admin">
                                    <img src={reglage}  />
                                </div>
                                <h2>Réglages Admin</h2>
                            </a>
                        </div>
                    </div>
                    
                </div>
            </div>
        );
    }
}

export default Menu;