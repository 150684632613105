import React, { useState } from "react";
import "./AddEST.css";

function AddEST() {
    const [modal, setModal] = useState(false);

    const toggleModalAddEST = () => {
        setModal(!modal);
    };

    if(modal) {
        document.body.classList.add('active-modal')
        
    } else {
        document.body.classList.remove('active-modal')
    }

    return(
        <div>
            <button onClick={toggleModalAddEST} className="button_add_do">
                Ajouter une entreprise sous-traitante
            </button>


            {modal && (
                <div className="new_EST">
                    <div>
                        <div className="name_newpt">ENTREPRISE SOUS-TRAINTANTE</div>
                        <div className="content_descnewpt_input">
                            <input type="text" placeholder="Nom de l'entreprise" />
                            <input type="text" placeholder="Site" />
                            <input type="text" placeholder="Service / Atelier" />
                            <input type="text" placeholder="Ligne / Étage" />
                            <input type="text" placeholder="Date de la PV/PDT" />
                            <input type="text" placeholder="Date de début des opérations" />
                            <input type="text" placeholder="Date de fin des opérations" />
                        </div>
                    </div>
                    <div>
                        <div className="name_newpt">EXÉCUTANT CHARGÉ DES TRAVAUX ST</div>
                        <div className="content_descnewpt_input">
                            <input type="text" placeholder="Prénom" />
                            <input type="text" placeholder="Nom" />
                            <input type="text" placeholder="Téléphone" />
                            <input type="text" placeholder="Email" />
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default AddEST;