import React, { Component } from "react";
import "./FinChantier.css";
import Navbar from "../../NavBar/Navbar";
import NavbarNewPT from "../NavbarNewPT/NavbarNewPT"
import HeaderNewPT from "../HeaderNewPT/HeaderNewPT";

class FinChantierPT extends Component {
    
    render() {
        return(
            <div>
                <Navbar />
                <NavbarNewPT />
                <HeaderNewPT titre='Intervenants' />
                <div className="outerbox_newpt">
                    <div className="content_ARnewpt">
                        fdsfdsf
                    </div> 
                </div>
                
            </div>
        );
    }
}

export default FinChantierPT;