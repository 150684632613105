import React, { Component } from "react";
import "./Validation.css";
import Navbar from "../../NavBar/Navbar";
import NavbarNewPT from "../NavbarNewPT/NavbarNewPT"
import HeaderNewPT from "../HeaderNewPT/HeaderNewPT";
import ImprimerValidateur from "../HeaderNewPT/BoutonsHeader/Validateur/ImprimerValidateur/ImprimerValidateur";
import VisaNonSignePT from "./VisaNonSigne/VisaNonSigne";
import VisaSignePT from "./VisaSigne/VisaSigne";
import AddValidateur from "../HeaderNewPT/BoutonsHeader/Validateur/AddValidateur/AddValidateur";

function ValidationPT() {
    return(
        <div>
            <Navbar />
            <NavbarNewPT />
            <HeaderNewPT titre='Validation' />
            <ImprimerValidateur />
            <AddValidateur />
            <div className="content_validationnewpt">
                <VisaNonSignePT />
                <VisaSignePT />

            </div> 
            
        </div>
    );
}

export default ValidationPT;