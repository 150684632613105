import React, { Component } from "react";
import Navbar from "./NavBar/Navbar";
import Header from "./Header/Header";
import "./TablePT.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from "@fortawesome/free-solid-svg-icons";
import MenuContextuel from "../PT/MenuContextuel/MenuContextuel";
import axios from "axios";
import AffichePT from "./AffichePT/AffichePT";

class PT extends Component {

    state = {
        pts : null
    }

    componentDidMount = () => {
        axios.get(`http://localhost/API-PT/front/ouverts`)
            .then(reponse => {
                this.setState({pts:Object.values(reponse.data)});
                // console.log(reponse);
            })
    }
    render() {
        return(
            <div>
                <Header titre='Ouverts' />
                <Navbar />
                <div className="tableau_pt">
                    <div className="tableau_header">
                        <div>
                            Fav
                        </div>
                        <div>
                            N°
                        </div>
                        <div>
                            <div>
                                Titre du PT
                            </div>
                            <div>
                                Nom de l'EE
                            </div>
                        </div>
                        <div>
                            <div>
                                Début
                            </div>
                            <div>
                                Fin
                            </div>
                        </div>
                        <div>
                            RdP
                        </div>
                        <div>
                            Options
                        </div>
                    </div>

                    
                        {
                            this.state.pts &&
                            this.state.pts.map(pt => {
                                return (
                                    <div className="tableau_content" key={pt.id}>
                                        <AffichePT {...pt}/>
                                        <MenuContextuel />
                                    </div>
                                )
                            })
                        }
                    
                </div>
            </div>
        );
    }
}

export default PT;