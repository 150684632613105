import React, { useState } from "react";
import "./Filtre.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from "@fortawesome/free-solid-svg-icons";

function Filtre(props) {
    const [modal, setModal] = useState(false);

    const toggleModal = () => {
        setModal(!modal);
    };

    if(modal) {
        document.body.classList.add('active-modal')
    } else {
        document.body.classList.remove('active-modal')
    }

    return(
        <div className="filtre">
            <button onClick={toggleModal} className="bouton_pt">
                    <FontAwesomeIcon icon={ faBars } />
                    Filtrer
            </button>


            {modal && (
                <div className="bandeau_filtre">
                    <div className="searchBar filtre_numero">
                            <input 
                                type="text"
                                placeholder="N° PT"
                            />
                    </div>
                    <div className="filter_titre_nom">
                        <div className="searchBar filtre_titre">
                            <input 
                                type="text"
                                name="searchBar"
                                id="searchBar"
                                placeholder="Titre du PT"
                            />
                        </div>
                        <div className="searchBar filtre_nom">
                            <input 
                                type="text"
                                name="searchBar"
                                id="searchBar"
                                placeholder="Nom de l'EE"
                            />
                        </div>
                    </div>
                    <div>
                        <div className="searchBar filtre_datedebut">
                            <input 
                                type="text"
                                name="searchBar"
                                id="searchBar"
                                placeholder="Date de début"
                            />
                        </div>
                        <div className="searchBar filtre_datefin">
                            <input 
                                type="text"
                                name="searchBar"
                                id="searchBar"
                                placeholder="Date de fin"
                            />
                        </div>
                    </div>
                    
                    <div className="searchBar filtre_do">
                        <input 
                            type="text"
                            name="searchBar"
                            id="searchBar"
                            placeholder="Donneur d'ordre"
                        />
                    </div>
                </div> 
            )}
        </div>
    )
}

export default Filtre;