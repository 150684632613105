import React from "react";
import "./VisaSigne.css";
import CardVS from "./CardVS/CardVS";

function VisaSignePT() {

    return(
        <div className="content_vspt">
            <div className="titre_vnspt">
                VISA SIGNÉ
            </div>
            <div className="display_vspt">
                <CardVS
                    prenom="Exploitant2"
                    nom=""
                    date="02/03/2022"
                    nameEE="HyperFluid"
                />
                <CardVS
                    prenom="CT"
                    nom=""
                    date="66/66/6666"
                    nameEE="Activision"
                />
                <CardVS
                    prenom="CT ST"
                    nom=""
                    date="08/07/1968"
                    nameEE="jhgfhf"
                />
            </div>
        </div>
    )
}

export default VisaSignePT;