import React, { Component } from "react";
import "./Recommandations.css";
import Navbar from "../../NavBar/Navbar";
import NavbarNewPT from "../NavbarNewPT/NavbarNewPT";
import HeaderNewPT from "../HeaderNewPT/HeaderNewPT";

class Recommandation extends Component {
    render() {
        return(
            <div>
                <Navbar />
                <NavbarNewPT />
                <HeaderNewPT titre="Recommandations EE"/>

                <div className="outerbox_newpt">
                    <div className="content_recommandations">
                        <div className="orga_recommandations">
                            <div className="name_newpt">ORGANISATION DU CHANTIER</div>
                            <div className="content_organewpt_input">
                                <input type="text" placeholder="Lieu de parking" />
                                <input type="text" placeholder="Zone de stockage de matériel" />
                                <input type="text" placeholder="Zone de travail" />
                                <input type="text" placeholder="Zone de restauration" />
                                <input type="text" placeholder="Toilettes" />
                            </div>
                        </div>
                        <div className="intervention_recommandations">

                            <div className="name_newpt">PRÉALABLE A L'INTERVENTION DE L'ENTREPRISE</div>

                            <div className="titre_bleu_recommandation">Avant l'intervention</div>

                            <p>Avant le démarrage des travaux, le permis de travail sera signé par tous les acteurs du permis de travail, à l’identique à la fin du poste de travail. <br /><br />Tant que le permis de travail n’est pas signé par le ou les exploitants, il est formellement interdit d’accéder à la zone de travail, à l’approvisionnement de matériels et de matériaux d’accéder à la zone de travail, à l’approvisionnement de matériels et de matériaux.<br /><br />En début de matinée ou de l’après-midi, l’entreprise prévient : DO, service sécurité, Chef Atelier ou le coordinateur de zone.</p>

                            <div className="titre_bleu_recommandation">Mode opératoire</div>

                            <p>Ce permis de travail est établi suivant le mode opératoire fourni par l’entreprise. <br />Les opérateurs devront avoir pris connaissance de ce mode opératoire, et l’avoir accepté.<br /><br />Ils devront également utiliser leur droit de retrait dès lors qu’ils se trouveront face à un danger. <br /><br />Toute modification du mode opératoire inclura un arrêt de poste de travail. <br /><br />Tant que le mode opératoire modifié n’est pas validé par le service sécurité, le poste de travail ne pourra pas reprendre.</p>

                            <div className="titre_bleu_recommandation">Organisation des secours</div>

                            <div className="st_recommandation">Obstacle en cas d'évacuation</div>
                            <p>L’entreprise maintiendra en permanence le chantier rangé et veillera à laisser libre le passage en cas d’évacuation et ou de l’arrivée des secours.</p>

                            <div className="st_recommandation">Point de rassemblement</div>
                            <p>En cas d’évacuation, l’entreprise cessera de suite toutes activités sur ce chantier et rejoindra le point de rassemblement des EE ou le point de rassemblement le plus proche en fonction du sinistre.</p>

                            <div className="st_recommandation">Accident</div>
                            <p>Infirmerie 7:30 - 16:30 lundi/mercredi<br />Par ordre de priorité<br />Infirmerie <br />Poste de garde 200 ou 04 77 52 72 00<br />Secours extérieur poste interne 8018 ou portable 18 (SDIS)</p>

                            <div className="st_recommandation">Départ d’incendie</div>
                            <p>Par ordre de priorité<br />Poste de garde 200 ou 04 77 52 72 00<br />Secours extérieur poste interne 8018 ou portable 18 (SDIS)</p>

                            <div className="st_recommandation">Service sécurité Badoit</div>
                            <p>Charles Scurbeck 06 26 48 89 45</p>

                            <div className="st_recommandation">Numéros utiles</div>
                            <p>Charles Scurbeck 06 26 48 89 45</p>
                            <p>Charles Scurbeck 06 26 48 89 45</p>
                            <p className="last_para">Charles Scurbeck 06 26 48 89 45</p>

                        </div>
                    </div>
                </div>


            </div>
        );
    }
}

export default Recommandation;